import React, { useState, useEffect } from 'react';
import './MultiPropertyOwnerForm.css';

const MultiPropertyOwnerForm = ({ className, language }) => {

  // States for form inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [portfolioType, setPortfolioType] = useState('');
  const [numOfProperties, setNumOfProperties] = useState('');
  const [isAvailableDays, setisAvailableDays] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // New state
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);

  // State for form validation errors
  const [error, setError] = useState('');

  // Email validation
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Function to check if all fields are filled
  const checkIfAllFieldsFilled = () => {
    const emailIsValid = isValidEmail(email);

    const allFieldsFilled =
      firstName &&
      lastName &&
      emailIsValid &&
      phone &&
      portfolioType &&
      numOfProperties &&
      acceptPrivacy;

    setIsAllFieldsFilled(allFieldsFilled);
  };

  // Update validation check on input change
  useEffect(() => {
    checkIfAllFieldsFilled();
  }, [firstName, lastName, email, phone, portfolioType, numOfProperties, acceptPrivacy]);

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    if (!firstName || !lastName || !email || !phone || !portfolioType || !numOfProperties || !acceptPrivacy) {
      setError('Please fill in all fields and accept the Privacy Policy.');
      return;
    }

    setError('');
    setFormSubmitted(true);
  };

  // Render success message if form is submitted
  if (formSubmitted) {
    return (
      <div id="pdg-to-chng" className="px-8 py-12 md:py-10 md:px-20 bg-white relative border border-red-500">
        <p className="text-xl text-green-600 success-msg">We received your request and someone will follow up in the next 1-2 business days.</p>
      </div>
    );
  }

  return (
    <div id="pdg-to-chng" className={`px-8 py-12 md:py-10 md:px-20 bg-white relative border border-red-500`}>
      <form
        id="multi-property-owner-form"
        onSubmit={handleSubmit}
        className={`multi-property-owner-form ${className}`}
      >
        {error && <div className="text-red-500 mb-4">{error}</div>}

        {/* First Name Field */}
        <div className="relative mb-6">
          <label htmlFor="firstName" className="text-sm text-navy mb-2">Your First Name</label>
          <input
            id="firstName"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="bg-transparent border-b outline-none py-1.5 w-full border-b-navy border-opacity-30"
            required
          />
        </div>

        {/* Last Name Field */}
        <div className="relative mb-6">
          <label htmlFor="lastName" className="text-sm text-navy mb-2">Your Last Name</label>
          <input
            id="lastName"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="bg-transparent border-b outline-none py-1.5 w-full border-b-navy border-opacity-30"
            required
          />
        </div>

        {/* Email Field */}
        <div className="relative mb-6">
          <label htmlFor="email" className="text-sm text-navy mb-2">Your Email Address</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-transparent border-b outline-none py-1.5 w-full border-b-navy border-opacity-30"
            required
          />
        </div>

        {/* Phone Field */}
        <div className="relative mb-6">
          <label htmlFor="phone" className="text-sm text-navy mb-2">Your Phone Number</label>
          <input
            id="phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="bg-transparent border-b outline-none py-1.5 w-full border-b-navy border-opacity-30"
            required
          />
        </div>

        {/* Portfolio Type Dropdown */}
        <div className="relative mb-6">
          <label htmlFor="portfolioType" className="text-sm text-navy mb-2">Portfolio Type</label>
          <select
            id="portfolioType"
            value={portfolioType}
            onChange={(e) => setPortfolioType(e.target.value)}
            className="bg-transparent border-b outline-none py-1.5 px-0 appearance-none w-full border-b-navy border-opacity-30 text-black"
            required
          >
            <option value="">Please select</option>
            <option value="Buy-to-Let (BTL) Residential">Buy-to-Let (BTL) Residential</option>
            <option value="HMO">HMO</option>
            <option value="Student Accommodation">Student Accommodation</option>
            <option value="Serviced Accommodation">Serviced Accommodation</option>
            <option value="Mixed-Use">Mixed-Use</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Number of Properties Field */}
        <div className="relative mb-6">
          <label htmlFor="numOfProperties" className="text-sm text-navy mb-2">No. of properties in your portfolio:</label>
          <select
            id="numOfProperties"
            value={numOfProperties}
            onChange={(e) => setNumOfProperties(e.target.value)}
            className="bg-transparent border-b outline-none py-1.5 px-0 appearance-none w-full border-b-navy border-opacity-30 text-black"
            required
          >
            <option value="">Please select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4-10">4-10</option>
            <option value="11-20">11-20</option>
            <option value="20+">20+</option>
          </select>
        </div>

        {/* Checkboxes */}
        <div className="flex items-start mb-6">
          <input
            id="isAvailableDays"
            type="checkbox"
            style={{ flex: '0 0 18px', height: '18px' }}
            checked={isAvailableDays}
            onChange={(e) => setisAvailableDays(e.target.checked)}
            className="border bg-transparent appearance-none outline-none border-navy border-opacity-30"
          />
          <label htmlFor="isAvailableDays" className="text-sm cursor-pointer pl-4 text-black">
            Your property is available 365 days a year.
          </label>
        </div>

        <div className="flex items-start mb-6">
          <input
            required
            id="acceptPrivacy"
            type="checkbox"
            style={{ flex: '0 0 18px', height: '18px' }}
            checked={acceptPrivacy}
            onChange={(e) => setAcceptPrivacy(e.target.checked)}
            className="border bg-transparent appearance-none outline-none border-navy border-opacity-30"
          />
          <label htmlFor="acceptPrivacy" className="text-sm cursor-pointer pl-4 text-black">
            By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>
          </label>
        </div>

        {/* Submit Button */}
        <div className="w-full">
          <button
            disabled={!isAllFieldsFilled}
            id="submit-btn"
            type="submit"
            className="btn btn--submit w-full hover:cursor-default hover:bg-yellow"
          >
            Request your market appraisal
          </button>
        </div>
      </form>
    </div>
  );
};

export default MultiPropertyOwnerForm;
